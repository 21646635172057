import * as React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { ServicesPageQuery } from '../data';
import { Layout, SEO, PageHero } from '../blocks';
import { Service } from '../components';

const Services: React.SFC = () => (
  <StaticQuery
    query={SERVICES_QUERY}
    render={(data: ServicesPageQuery) => (
      <Layout>
        <SEO title={`NuWave Commerce | About Us`} />
        <PageHero
          title={data.service.frontmatter.tagline}
          subTitle={data.service.frontmatter.subTagline}
        />
        {data.blocks.edges.map(({ node }) => (
          <Service {...node} reverse={node.meta.order % 2 === 0} />
        ))}
      </Layout>
    )}
  />
);

const SERVICES_QUERY = graphql`
  query ServicesPageQuery {
    service: markdownRemark(frontmatter: { path: { eq: "/services" } }) {
      id
      html
      frontmatter {
        title
        tagline
        subTagline
        path
      }
    }
    blocks: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "service" } } }
      sort: { fields: frontmatter___order }
    ) {
      edges {
        node {
          ...Service
        }
      }
    }
  }
`;

export default Services;
